import React from 'react';
import './App.css';
import Plx from 'react-plx';

const experienceList = [
  { month: 'nov', year: '21', descriptor: 'Lead Developer at', company: 'ARTXP' },
  { month: 'nov', year: '19', descriptor: 'Head Of Development at', company: 'Applied Technologies A/S' },
  { month: 'nov', year: '18', descriptor: 'Freelance software developer at', company: 'K. Birk' },
  { month: 'aug', year: '18', descriptor: 'Full-stack developer at', company: 'Rainmaking' },
  { month: 'dec', year: '17', descriptor: 'Backend developer at', company: 'SpotON Marketing' },
  { month: 'feb', year: '16', descriptor: 'Full-stack developer at', company: 'Brandmonkey' },
  { month: 'aug', year: '15', descriptor: 'IT consultant at', company: 'Rotary' },
  { month: 'aug', year: '13', descriptor: 'Web developer at', company: 'Surftown A/S' },
  { month: 'may', year: '08', descriptor: 'Freelance developer at', company: 'Birk Software Solutions' },
  { month: 'jun', year: '06', descriptor: 'Web programmer at', company: 'Skandiaweb A/S' },
]

const credentialsData = [
  {
    start: 0,
    end: 500,
    properties: [
      {
        startValue: 0,
        endValue: -100,
        property: "translateY"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ],
  },
]

const experienceData = [
  {
    start: 100,
    end: 600,
    properties: [
      {
        startValue: -100,
        endValue: 0,
        property: "translateY"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ],
  },
];

const experienceEntryData = [
  {
    start: 'self',
    duration: 300,
    properties: [
      {
        startValue: 0,
        endValue: 120,
        property: "translateY"
      },
    ],
  },
  {
    start: 'self',
    startOffset: 400,
    duration: 600,
    properties: [
      {
        startValue: 120,
        endValue: -20,
        property: "translateY"
      },
    ],
  },
]

const experienceDotEntryData = [
  {
    start: 'self',
    startOffset: 400,
    duration: 250,
    properties: []
  },
]

function App() {
  return (
    <>
      <div className="mobile">
        <div className='mobile-information'>
          <h1>Kaspar Birk</h1>
          <h3>Software developer</h3>
          <ul>
            <li>
              <a href='https://github.com/imbue11235'>
                <i className='fa fa-github'/>
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/kasparbirk/'>
                <i className='fa fa-linkedin'/>
              </a>
            </li>
            <li>
              <a href='mailto:me@kasparbirk.com'>
                <i className='fa fa-envelope'/>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="app">
        <div className="credentials">
          <div className="credentials__display">
            <Plx parallaxData={ credentialsData }>
              <h1>Kaspar Birk</h1>
              <h3>Software developer</h3>
            </Plx>
          </div>  
        </div>
        <div className="experience">
          <div className="experience__header">
            <Plx parallaxData={ experienceData }>
              <h2>Experience</h2>
            </Plx>
          </div>
          <div className="experience__timeline">
            <div className="experience__timeline__line"/>
            {
              experienceList.map((value, index) => {
                return (
                  <Plx key={ index } parallaxData={ experienceEntryData } className="experience__timeline__entry">
                      <div className='date'>
                        <div className='month'>{ value.month }</div>
                        <div className='year'>'{ value.year }</div>
                      </div>
                      <Plx parallaxData={ experienceDotEntryData } className='dot'/>
                      <h4><span>{ value.descriptor } </span>{ value.company }</h4>
                  </Plx>
                )
              })
            }
          </div>
        </div>
        <div className="contact">
          <div className="email">
            <a href='mailto:me@kasparbirk.com'>
              <h4>me@kasparbirk.com</h4>
            </a>
          </div>
          <div className="social-buttons">
            <a href='https://github.com/imbue11235' className='button github'>
              <i className='fa fa-github'/>
            </a>
            <a href='https://www.linkedin.com/in/kasparbirk/' className='button linkedin'>
              <i className='fa fa-linkedin'/>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
